import { TizenKeysContext } from '@/contexts/TizenKeysContext'
import { xboxKeyForEvent } from '@/utils/xbox/xboxKeyForEvent'
import { useContext, useEffect, useRef } from 'react'
type KeyOrKeys = string | string[]
const useKeyEvent = (keyOrKeys: KeyOrKeys, callback: (event: KeyboardEvent) => void) => {
  const { keyForEvent } = useContext(TizenKeysContext)
  const ref = useRef({
    keyOrKeys,
    callback,
  })

  ref.current = { keyOrKeys, callback }

  useEffect(() => {
    const { keyOrKeys, callback } = ref.current
    const keys = Array.isArray(keyOrKeys) ? keyOrKeys : [keyOrKeys]
    const handleKeyboardEvent = (event: KeyboardEvent) => {
      const xboxKey = xboxKeyForEvent(event)
      const tizenKey = keyForEvent(event)
      const eventStrs: (string | undefined)[] = [event.key, event.code, xboxKey.key, tizenKey.name]
      const matches = keys.some((key) => {
        if (key === '*') {
          return true
        }
        return eventStrs.some((str) => {
          return str === key || str?.toLowerCase() === key?.toLowerCase()
        })
      })
      if (matches) {
        callback(event)
      }
    }
    document.addEventListener('keyup', handleKeyboardEvent, { passive: true })
    return () => {
      document.removeEventListener('keyup', handleKeyboardEvent)
    }
  }, [keyForEvent])
}

export default useKeyEvent
