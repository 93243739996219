import { UiContext } from '@/contexts/UiContext'
import useKeyEvent from '@/hooks/useKeyEvent'
import { HeadingLabel, PrimaryLabel } from '@/styles/fonts'
import { isNotInProd } from '@grandstand-web/bally-web-shared/src/utils/envUtils'
import { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { InteractiveDebugMenu } from './InteractiveDebugMenu'

const Container = styled.div`
  position: absolute;
  z-index: 1000;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  background-color: var(--surface);
  position: fixed;
  color: white;
  padding: 64px;
  overflow-wrap: break-word;
  overflow-y: auto;
  text-align: left;
  font-size: 24px;
  font-family: var(--default-font);
  display: flex;
  flex-direction: column;
  gap: 32px 0;
`

const DebugMenu = () => {
  const { isDebugMenuVisible: isVisible, setIsDebugMenuVisible: setVisible } = useContext(UiContext)
  const [currentKey, setCurrentKey] = useState<KeyboardEvent | undefined>()
  const isProd = !isNotInProd()
  // if isVisible && isProd, hide the debug menu
  useEffect(() => {
    if (!isVisible) {
      return
    }
    if (isProd) {
      setVisible(false)
    }
    return () => {}
  }, [isVisible, setVisible, isProd])

  const handleToggle = (_: KeyboardEvent) => {
    if (isProd) {
      return
    }
    setVisible((prev) => !prev)
  }

  // reload the page
  const handleReload = (_: KeyboardEvent) => {
    window.location.reload()
  }

  // listen for F1/GamepadY to toggle the debug menu
  useKeyEvent(['F1', 'GamepadY'], handleToggle)

  // listen for F2/GamepadX to reload the page
  useKeyEvent(['F2', 'GamepadX'], handleReload)

  useEffect(() => {
    const handleKey = (evt: KeyboardEvent) => {
      evt.preventDefault()
      setCurrentKey(evt)
      return false
    }
    addEventListener('keyup', handleKey, { capture: true })
    return () => {
      removeEventListener('keyup', handleKey)
    }
  }, [])
  if (!isVisible || isProd) {
    return <></>
  }
  return (
    <Container>
      <HeadingLabel fontSize="8">Debug Menu</HeadingLabel>
      <div>
        <PrimaryLabel fontSize="xl">
          Current Keypress - Code: {currentKey?.code ?? 'unknown'}, Key: {currentKey?.key}, KeyCode:{' '}
          {currentKey?.keyCode ?? -1}
        </PrimaryLabel>
      </div>
      <InteractiveDebugMenu />
    </Container>
  )
}

export default DebugMenu
